(() => {
  if (window.customElements.get('pr-image-fallback')) return;

  class PrideraiserImageFallback extends HTMLElement {
    #intervalId: ReturnType<typeof setTimeout> | null = null;
    #checks = 0;

    get hashedId() {
      return this.dataset.hashedId;
    }
    set hashedId(value) {
      this.dataset.hashedId = value;
    }

    async #fetchImageData() {
      const apiUrl = `${document.body.dataset.apiBase?.replace(
        /\/$/,
        ''
      )}/images/${this.hashedId}.json`;
      try {
        const imageData = await fetch(apiUrl).then((response) =>
          response.json()
        );
        if (imageData.markup) {
          const template = document.createElement('template');
          template.innerHTML = imageData.markup;
          this.replaceWith(template.content);
        } else {
          throw new Error('No image data found');
        }
      } catch (error) {
        if (this.#checks > 300) {
          return;
        }
        this.#intervalId = setTimeout(() => {
          this.#checks = this.#checks + 5;
          this.#fetchImageData();
        }, 1000 * this.#checks);
      }
    }

    connectedCallback() {
      if (!this.hashedId) return;
      this.#fetchImageData();
    }
  }

  window.customElements.define('pr-image-fallback', PrideraiserImageFallback);
})();
